import { navigate } from 'gatsby'
import React from 'react'
import {Menu, Container} from 'semantic-ui-react'


class Header extends React.Component {
  state = { activeItem: 'home' }

  componentDidMount() {
    console.log(window.location)
    let name 
    if (window.location.pathname.length >= 2){
      name = window.location.pathname.substr(1)

    } 
    else {
      name = 'About'
    }
    this.setState({ activeItem: name })
  }

  handleItemClick = (e, { name }) =>{

  

  navigate(`/${name === 'About' ? '/' : name}`)
  }
  
  render() {
    const { activeItem } = this.state
return(
    <div>
      <Container textAlign='center'>
        <Menu compact pointing secondary  size='massive' widths='6'>
          <Menu.Item name='About' active={activeItem === 'About'} onClick={this.handleItemClick} />
        
          <Menu.Item
            name='Pictures'
            active={activeItem === 'Pictures'}
            onClick={this.handleItemClick}
          />
          

          <Menu.Item
            name='Mathgames'
            active={activeItem === 'Mathgames'}
            onClick={this.handleItemClick}
          >
          Math games</Menu.Item>
      
          <Menu.Item
            name='Contact'
            active={activeItem === 'Contact'}
            onClick={this.handleItemClick}
          />
        </Menu>
        </Container>
      </div>
)
  }
}



export default Header
